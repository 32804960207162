<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import wxUtils from "./utils/wxUtils";

export default {
  mounted() {
    setTimeout(() => {
      wxUtils.share({
        title: "文化艺术交流协会",
        desc: "文化艺术交流协会",
        link: this.$store.state.initLink,
        imgUrl:
          "https://qgyc-system.oss-cn-hangzhou.aliyuncs.com/artculture/logo.jpg",
      });
    }, 500);
  },
};
</script>

<style lang="less"></style>
